


































































































































import { Component, Vue, Prop } from 'nuxt-property-decorator'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { AtSchoolsApi, RegisterToAtSchoolRequest } from '~/apis'
import PhoneInput from '~/components/profile/PhoneInput.vue'
import { showToast } from '~/utils/functions'

interface FormDataDto {
  firstName: string
  lastName: string
  schoolName: string
  email: string
  phoneNumber: string
  message: string
}

interface IndexPageContentDto {
  hero: {
    title: string
    subTitle: string
  }
  body: {
    title: string
    description: string
    formButtonContent: string
  }
}

@Component({
  components: {
    ValidationObserver,
    ValidationProvider,
    PhoneInput
  }
})
export default class AtSchoolIndexPage extends Vue {
  @Prop({ required: true }) pageIndexContent!: IndexPageContentDto

  formData: FormDataDto = {
    firstName: '',
    lastName: '',
    schoolName: '',
    email: '',
    message: '',
    phoneNumber: ''
  }

  isValidNumberPhone = false

  get heroContent () {
    return this.pageIndexContent.hero
  }

  get bodyContent () {
    return this.pageIndexContent.body
  }

  resetForm () {
    this.formData = {
      firstName: '',
      lastName: '',
      schoolName: '',
      email: '',
      message: '',
      phoneNumber: ''
    }
    requestAnimationFrame(() => {
      (this.$refs.observer as any).reset()
    })
  }

  validatePhoneNumber () {
    (this.$refs.phoneInput as any).validatePhone()
  }

  async validateFormData () {
    return await (this.$refs.observer as any).validate()
  }

  async onSubmit () {
    this.validatePhoneNumber()
    const isValid = await this.validateFormData()
    if (!this.isValidNumberPhone || !isValid) {
      return
    }

    const request: RegisterToAtSchoolRequest = {
      firstName: this.formData.firstName,
      lastName: this.formData.lastName,
      email: this.formData.email,
      message: this.formData.message,
      phoneNumber: this.formData.phoneNumber,
      schoolName: this.formData.schoolName
    }

    const loading = this.$loading.show()
    await new AtSchoolsApi().registerToAtSchool(request)
      .then(() => {
        showToast({
          text: 'Registration email sent'
        })
        this.resetForm()
      })
      .finally(() => {
        loading.hide()
      })
  }

  changeNumberPhone ($e: string, isValid: boolean) {
    this.formData.phoneNumber = $e
    this.isValidNumberPhone = isValid
    if (!this.isValidNumberPhone) {
      this.formData.phoneNumber = ''
      return
    }

    if (this.formData.phoneNumber.trim() !== '') {
      this.isValidNumberPhone = true
    } else {
      this.isValidNumberPhone = false
    }
  }
}
