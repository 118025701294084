

























import { Component, Vue, Prop } from 'nuxt-property-decorator'
import { SchoolDto } from '~/apis'

@Component({})
export default class AtSchoolBrowseCoursesHeaderComponent extends Vue {
  @Prop({ required: true }) schoolData!: SchoolDto

  get content () {
    return {
      schoolName: this.schoolData.name,
      venueImage: this.schoolData.venue?.image || ''
    }
  }
}
