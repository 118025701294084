//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import 'intl-tel-input/build/css/intlTelInput.css'
import intlTelInput from 'intl-tel-input'

export default {
  name: 'PhoneInput',
  props: {
    value: {
      type: String,
      default: ''
    },
    requiredMessage: {
      type: String,
      default: 'Please input phone number'
    },
    invalidPhoneMessage: {
      type: String,
      default: 'Phone number is incorrect.'
    },
    isRequired: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      error: false,
      telInput: null,
      errorMessage: ''
    }
  },
  watch: {
    value () {
      this.$refs.phone.value = this.value
    }
  },
  mounted () {
    const inputElement = this.$refs.phone
    this.$refs.phone.value = this.value
    this.telInput = intlTelInput(inputElement, {
      utilsScript:
        'https://cdnjs.cloudflare.com/ajax/libs/intl-tel-input/17.0.12/js/utils.min.js',
      preferredCountries: ['sg']
    })
  },
  methods: {
    changeValue () {
      const formattedNumber = this.telInput.getNumber()
      this.$refs.phone.value = formattedNumber
      this.validatePhone()
      this.$emit('changeNumberPhone', formattedNumber, this.telInput.isValidNumber())
    },
    validatePhone () {
      const formattedNumber = this.telInput.getNumber()
      if (formattedNumber.trim().length === 0) {
        if (this.isRequired) {
          this.error = true
          this.errorMessage = this.requiredMessage
        } else if (!this.isRequired && typeof this.isRequired === 'boolean') {
          this.error = false
          this.errorMessage = ''
        }
      } else if (!this.telInput.isValidNumber()) {
        this.error = true
        this.errorMessage = this.invalidPhoneMessage
      } else {
        this.error = false
        this.errorMessage = ''
      }
    }
  }
}
