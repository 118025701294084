
























import { Context } from '@nuxt/types'
import { Component, Vue } from 'nuxt-property-decorator'
import * as router from 'vue-router'
import orderBy from 'lodash/orderBy'
import { AtSchoolsApi, CoursesApi, GetProvidersResponseData, HomePageContentDto, HomePageImageDto, HomePageWhatWeDoDto, ProvidersApi, StrapiApi, SubjectsApi } from '~/apis'
import AtSchoolBrowseCoursesHeaderComponent from '~/components/at-school/BrowseCoursesHeader.vue'
import { AtSchoolTabDto, FromPage } from '~/types'
import { shortenString } from '~/utils/functions'
import AgoraIndexPage from '~/components/content/AgoraIndexPage.vue'
import AtSchoolIndexPage from '~/components/content/AtSchoolIndexPage.vue'

interface IndexPageContentDto {
  hero: {
    title: string
    subTitle: string
  }
  body: {
    title: string
    description: string
    formButtonContent: string
  }
}

@Component({
  components: {
    AtSchoolBrowseCoursesHeaderComponent,
    AgoraIndexPage,
    AtSchoolIndexPage
  },
  async asyncData (context: Context) {
    if (context.store.state.currentViewFor === FromPage.Agora) {
      const all = await Promise.all([
        new SubjectsApi().getSubjects(),
        new ProvidersApi().getProviders({ availableHomepage: true }),
        new StrapiApi().getHomepageContent(),
        new CoursesApi().getCourseEventsHomepage(),
        new StrapiApi().getHomePageWhatWeDo(),
        new StrapiApi().getHomePageImage()
      ])
      const { data: { data: homepageContents } } = all[2]
      const homepageImage = all[5].data.data
      const heroImage = homepageImage[Math.floor(Math.random() * homepageImage.length)].image

      return {
        isAtSchoolPage: false,
        subjectsResponse: all[0].data.data,
        providersResponse: all[1].data.data,
        providerList: orderBy(all[1].data.data.summary, [provider => provider && provider.name?.toLowerCase()], ['asc']),
        homepageContents,
        courseEventsHomepage: all[3].data.data,
        courseEventsResponse: orderBy(all[3].data.data, ['startTime'], ['asc']),
        homepageWhatWeDo: all[4].data.data,
        homepageImage,
        heroImage
      }
    } else if (context.store.state.currentViewFor === FromPage.AtSchool) {
      if (context.store.state.authenticated) {
        const { data: { data: headerSchools } } = await new AtSchoolsApi().getSchoolsByParentId()
        const tabs: AtSchoolTabDto[] = []
        headerSchools.forEach((headerValue) => {
          tabs.push({
            name: headerValue.name,
            href: `/${headerValue.slug}`,
            image: headerValue.image
          })
          context.store.commit('setSchoolsHeader', tabs)
          if (tabs && tabs.length > 0) {
            context.redirect({ path: tabs[0].href })
          }
        })
      }

      const [
        { data: { data: pageIndexContent } }
      ] = await Promise.all([
        new StrapiApi().getAtSchoolIndexPageContent()
      ])

      return {
        pageIndexContent,
        isAtSchoolPage: true
      }
    }
  }
})
export default class Index extends Vue {
  isAtSchoolPage: boolean = false
  providersResponse: GetProvidersResponseData = { summary: [], providers: [] };
  isShow: boolean = true
  isShowPromptMessage: boolean = false
  homepageWhatWeDo: HomePageWhatWeDoDto = { data: [] }
  homepageImage: HomePageImageDto = { data: [] }

  prevPage = ''
  loggedIn = false

  homepageContents: HomePageContentDto = {
    featureList: [],
    sliderList: [],
    feedbackList: [],
    meta: {},
    browseCourseButton: {},
    HeroTextColor: '',
    primaryButton: undefined,
    ghostButton: undefined
  }

  heroImage: string = ''

  pageIndexContent: IndexPageContentDto = {
    hero: {
      title: '',
      subTitle: ''
    },
    body: {
      title: '',
      description: '',
      formButtonContent: ''
    }
  }

  head () {
    if (!this.isAtSchoolPage) {
      const title = this.homepageContents.pageTitle || 'Agora'
      const metaDescription = this.homepageContents.metaDescription || this.homepageContents?.meta?.description || ''
      const metaKeywords = this.homepageContents.metaKeywords || ''
      const image = this.homepageContents?.meta?.imageUrl ?? this.heroImage

      return {
        title,
        meta: [
          { hid: 'description', name: 'description', content: shortenString(metaDescription || '', 160) },
          { hid: 'og:url', property: 'og:url', content: `${process.env.REDIRECTURI + this.$route.path}` },
          { hid: 'og:title', property: 'og:title', content: title },
          { hid: 'og:description', property: 'og:description', content: `${shortenString(metaDescription, 160) || ''}` },
          { hid: 'og:image', property: 'og:image', content: `${image}` },
          { hid: 'keywords', name: 'keywords', content: metaKeywords }
        ]
      }
    } else {
      const title = 'Agora at School'
      const image = `${process.env.REDIRECT_AT_SCHOOL_URI}/AgoraAtSchool_Logo_small.png`

      return {
        title,
        meta: [
          { hid: 'og:title', property: 'og:title', content: title },
          { hid: 'og:image', property: 'og:image', content: image }
        ]
      }
    }
  }

  created () {
    if (!this.isAtSchoolPage) {
      const loggedIn = this.$store.state.authenticated
      const path = this.$store.state.route.path
      if (loggedIn && !!this.$store.state.user) {
        const isParent = this.$store.state.user?.userType === 'parent'
        if (isParent) {
          const latestLocation = this.$store.state.latestLocation as router.Location
          if (latestLocation) {
            window.location.replace(latestLocation.path!)
            this.$store.commit('setLatestLocation', {
              name: '',
              params: {},
              path: ''
            })
          }
        } else {
          this.$router.push('/education-provider/')
        }
      }
      this.prevPage = path
      this.loggedIn = loggedIn
      this.setStoreStyleHomePage()
    }
  }

  mounted () {
    if (!this.isAtSchoolPage) {
      if (process.env.NODE_ENV === 'production') {
        this.isShow = false
      }
      this.isShowPromptMessage = this.$store.state.newSignUp && this.loggedIn
    }
  }

  setStoreStyleHomePage () {
    this.$store.commit('styles/setMainMenuFontColour', this.homepageContents.MainMenuFontColour)
    this.$store.commit('styles/setLogoFontColour', this.homepageContents.LogoFontColour)
  }

  beforeDestroy () {
    if (!this.isAtSchoolPage) {
      this.$store.commit('setHomePageContent', null)
    }
  }
}
